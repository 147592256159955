import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=86be9034&scoped=true&"
import script from "./notice.vue?vue&type=script&lang=js&"
export * from "./notice.vue?vue&type=script&lang=js&"
import style0 from "./notice.vue?vue&type=style&index=0&id=86be9034&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86be9034",
  null
  
)

export default component.exports